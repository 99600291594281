.cb-slideshow{
    list-style:none;
}
.cb-slideshow, .cb-fixed{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0; 
}
.cb-fixed{
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
     background-repeat: no-repeat;
}
.cb-fixed.bravecto{
    background-image: url(../images/fullscreen/bravecto.jpg);
}
.cb-fixed.bravecto_enesimo{
    background-image: url(../images/fullscreen/bravecto_enesimo.jpg);
}
.cb-fixed.bravecto_plus{
    background-image: url(../images/fullscreen/bravecto_plus.jpg);
}
.cb-fixed.scalibor{
    background-image: url(../images/fullscreen/scalibor.jpg);
}
.cb-fixed.nobivac{
    background-image: url(../images/fullscreen/nobivac.jpg);
}
.cb-fixed.letifend{
    background-image: url(../images/fullscreen/letifend.jpg);
}
.cb-fixed.geniki_therapeia{
    background-image: url(../images/fullscreen/geniki_therapeia.jpg);
}
.cb-fixed.epomeni_episkepsi{
    background-image: url(../images/fullscreen/epomeni_episkepsi.jpg);
}

/*
.cb-slideshow:after {
    content: '';
    background: transparent url(../images/pattern.png) repeat top left;
}
*/
.cb-slideshow li span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
     background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
	-webkit-backface-visibility: hidden;
    -webkit-animation: imageAnimation 30s linear infinite 0s;
    -moz-animation: imageAnimation 30s linear infinite 0s;
    -o-animation: imageAnimation 30s linear infinite 0s;
    -ms-animation: imageAnimation 30s linear infinite 0s;
    animation: imageAnimation 30s linear infinite 0s;
}
.cb-slideshow li:nth-child(1) span {
    background-image: url(../images/fullscreen/rotate/2.jpg);
}
.cb-slideshow li:nth-child(2) span {
    background-image: url(../images/fullscreen/rotate/3.jpg);
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) span {
    background-image: url(../images/fullscreen/rotate/4.jpg);
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    -o-animation-delay: 12s;
    -ms-animation-delay: 12s;
    animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) span {
    background-image: url(../images/fullscreen/rotate/5.jpg);
    -webkit-animation-delay: 18s;
    -moz-animation-delay: 18s;
    -o-animation-delay: 18s;
    -ms-animation-delay: 18s;
    animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) span {
    background-image: url(../images/fullscreen/rotate/6.jpg);
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s;
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation { 
    0% { opacity: 0;
    -webkit-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -webkit-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-moz-keyframes imageAnimation { 
    0% { opacity: 0;
    -moz-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -moz-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-o-keyframes imageAnimation { 
    0% { opacity: 0;
    -o-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -o-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@-ms-keyframes imageAnimation { 
    0% { opacity: 0;
    -ms-animation-timing-function: ease-in; }
    8% { opacity: 1;
         -ms-animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}
@keyframes imageAnimation { 
    0% { opacity: 0;
    animation-timing-function: ease-in; }
    8% { opacity: 1;
         animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}

/* Show at least something when animations not supported */
.no-cssanimations .cb-slideshow li span{
	opacity: 1;
}