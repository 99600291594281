/**********************************************************************************************
                                       Popup inserimento reminder aggiuntivi
**********************************************************************************************/
#more-reminder-container div.modal-content {
    padding: 0 0 2rem 0;
  }
  #more-reminder-container h4.modal-title {
    font-weight: bold;
    margin-bottom: 3rem;
  }
  
  button.btn.btn-next {
    border-bottom: 0;
    background-color: #fff;
    border: 2px solid;
    margin: 1rem auto;
    font-weight: bold;
  }
  button.btn.btn-next.bravecto {
    border-color: #4e2d7c;
    color: #4e2d7c;
  }  
  button.btn.btn-next.bravecto_enesimo {
    border-color: #a17044;
    color: #a17044;
  }
  button.btn.btn-next.scalibor {
    border-color: #f9b041;
    color: #f9b041;
  }
  button.btn.btn-next.bravecto_plus {
    border-color: #e71b39;
    color: #e71b39;
  }
  button.btn.btn-next.nobivac {
    border-color: #5B8FC5;
    color: #5B8FC5;
  }
  button.btn.btn-next.letifend {
    border-color: #243444;
    color: #243444;
  }
  button.btn.btn-next.epomeni_episkepsi {
    border-color: #009b97;
    color: #009b97;
  }
  button.btn.btn-next.geniki_therapeia {
    border-color: #65707e;
    color: #65707e;
  }
  button.btn.btn-next.bravecto.active {
    background-color: #4e2d7c;
    color: #fff;
  }
  button.btn.btn-next.scalibor.active {
    background-color: #f9b041;
    color: #fff;
  }
  button.btn.btn-next.bravecto_plus.active {
    background-color: #e71b39;
    color: #fff;
  }
  button.btn.btn-next.bravecto_enesimo.active {
    background-color: #a17044;
    color: #fff;
  }
    button.btn.btn-next.nobivac.active {
    background-color: #243444;
    color: #fff;
  }
  button.btn.btn-next.letifend.active {
    background-color: #5B8FC5;
    color: #fff;
  }
  button.btn.btn-next.epomeni_episkepsi.active {
    background-color: #009b97;
    color: #fff;
  }
  button.btn.btn-next.geniki_therapeia.active {
    background-color: #65707e;
    color: #fff;
  }
  #btn_cancel_more_reminder,
  #btn_more_reminder {
    margin-top: 1rem;
    border-bottom: 0;
  }
  #btn_cancel_more_reminder {
    background-color: #005363;
  }
  #btn_more_reminder.btn-disabled {
    background-color: #d5d5d5;
    color: #fff;
  }
  