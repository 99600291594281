/**********************************************************************************************
                                          Regole globali
**********************************************************************************************/

html, body {
  font-family: "Invention", Arial;
  font-size: 14.5px;
  color: $msd_blue_color;
}
.table{
  color: $msd_blue_color;
}
a{
  color: #337ab7;
  text-decoration: none;
}
.text-justify{
  text-align: justify;
}
.form-label{
  margin-bottom: 0.2rem;
}
input[type=checkbox]{
  margin-right: 0.3rem;
}
.hide{
  display: none !important;
}
h3.section_title{
  color: $verde;
  font-weight: 600;
}
/**********************************************************************************************
                                          Bottoni
**********************************************************************************************/
.btn{
  background-color: $verde;
  border: none;
  //border-radius: 0;
  border-bottom: 2px solid $verde_border;
  width: 100%;
}
.btn.verde_scuro{
  background-color: $verde_scuro;
  color: #ffffff;
  border-bottom: none;
}
.container-fluid{
  padding: 0;
}

//nuovo menu responsive
.navbar {
  // position: relative;
  background: $body-bg;
  border-bottom: 2px solid #005463;
  // margin: 0;
.navbar-toggler{
  border-color:  $verde;
  color: $verde;
  &.custom-toggler .navbar-toggler-icon {
    background-image: url(
    "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 133, 124)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}
  .navbar-brand {
    // margin-top: 5px;
    // margin-bottom: 5px;
    border-right: 1px solid #c1c2c4;
    // line-height: 60px;
    img {
      // height: 55px;
      height: auto;
      max-height: 40px;
      width: auto;
    }
  }
  .msd-small{
    img
    {max-width: 80px;}
  }
  .navbar-nav{
    line-height: 60px;
    text-align: center;
    padding-right: 0;



    a{
      color: $verde;
    }
    a:hover{
      text-decoration: none;
    }

    .active{
      // line-height: 65px;
      margin: 0;
      color: $verde;
      font-weight: bolder;
      // background: $verde;
      // background: -moz-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -webkit-gradient(left top, left bottom, color-stop(0%, $verde), color-stop(100%, $verde_scuro), color-stop(100%, $verde_scuro));
      // background: -webkit-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -o-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -ms-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: linear-gradient(to bottom, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$verde', endColorstr='$verde_scuro', GradientType=0);
      // margin-left: -1px;
      border-bottom: 5px solid $verde;

      a{
        // color: #ffffff;
        font-weight: bold;
      }
    }
    .nav-item{
      // float: right;
      padding-left: 15px;
      padding-right: 15px;
      &.border-sides{
        border-left: 1px solid $verde_border;
        border-right: 1px solid $verde_border;
        margin: 0;
        // line-height: 70px;
        font-size: 12px;
      }
      &.codice span{
        margin: 0;
      }
    }
  }
}
//fine nuovo menu responsive

.header_full {
  position: relative;
  background: $body-bg;
  border-bottom: 2px solid #005463;
  margin: 0;

  .logo {
    margin-top: 5px;
    margin-bottom: 5px;
    border-right: 1px solid #c1c2c4;
    line-height: 60px;
    img {
      // height: 55px;
      height: auto;
      max-height: 55px;
      width: auto;
    }
  }
  .msd{
    line-height: 60px;
    text-align: right;
  }
  .top-right{
    line-height: 60px;
    text-align: center;
    padding-right: 0;

    div{
      // border-right: 1px solid $verde;
      margin: 5px 0;
    }

    span{
      margin-left: 10px;
    }
    a{
      color: $verde;
    }
    a:hover{
      text-decoration: none;
    }

    div.active{
      line-height: 65px;
      margin: 0;
      // background: $verde;
      // background: -moz-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -webkit-gradient(left top, left bottom, color-stop(0%, $verde), color-stop(100%, $verde_scuro), color-stop(100%, $verde_scuro));
      // background: -webkit-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -o-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: -ms-linear-gradient(top, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // background: linear-gradient(to bottom, $verde 0%, $verde_scuro 100%, $verde_scuro 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$verde', endColorstr='$verde_scuro', GradientType=0);
      // margin-left: -1px;
      border-bottom: 5px solid $verde;

      a{
        // color: #ffffff;
        font-weight: bold;
      }
    }
    .menu-item{
      float: right;
      padding-left: 15px;
      padding-right: 15px;
      &.border-sides{
        border-left: 1px solid $verde_border;
        border-right: 1px solid $verde_border;
        margin: 0;
        line-height: 70px;
        font-size: 12px;
      }
      &.codice span{
        margin: 0;
      }
    }
  }
}
.container-full {
  margin: 0;
  &.mt-3{
    margin-top: 3em;
  }
  &.footer_box_div{
    background-color: white;
    color:$footer_text;
    padding: 70px 30px;
    // padding: 70px 30px 70px 70px;
    // background-image: url("../images/msd-logo-sidebar.png");
    // background-position: left;
    // background-repeat: no-repeat;
    // background-size: auto;

    
  }
}

.footer_box{
  background-color: $bg_animal;
}
.footer_copyright{
  position: relative;
  padding: 20px;
  font-size: 10px;
  color:$footer_text;
  // border-top: 1px solid $msd_blue_color;
}
.footer_privacy{
  position: relative;
  margin-bottom: 20px;
  font-size: 13px;
  color:$footer_text;
  // color: #ffffff;

  a{
    // background: url('../images/black.png') repeat 0 0;
    // padding: 5px 20px;
    // color: #ffffff;
    color:$footer_text;
    text-decoration: none;
    cursor: pointer;
    &.msd-privacy{
      color: $verde;
    }
    &:hover{
      color: $verde;
    }
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}
.touch-area {

   display: grid;
  
   grid-template-areas:
  
    'a b '

    'bar bar'
  
    'd c';
    grid: gap 10px;
  
    
  
   @media screen and (max-width: 767px) {
  
    grid-template-areas:
  
    'a'
    'b'
    'bar'
    'd'
    'c';
  
   }
  
    
  
   grid-gap: 10px;
  
  }
  
  .touch-bar{
    grid-area: bar;
  }
  
  .touch-a {
  
   grid-area: a;
  
  }
  
    
  
  .touch-b {
  
   grid-area: b;
  
  }
  
    
  
  .touch-c {
  
   grid-area: c;
   @media (max-width:767px) {
    width: 100%;
    margin-left: auto;
   }
  
  }
  .touch-d {
  
    grid-area: d;
    @media (max-width:767px) {
     margin-left: auto;
    }
   
   }
@media (max-width: 1024px){
  .header_full .top-right .menu-item{
    padding: 0 15px;
  }
  .header_full .logo img{
    height: auto;
    width: 100%;
  }
  .container-reminder{
    .content-left{
      .box-activity .type-activity img{
        width: 90%;
      }
    }
    .content-reminder {
      .box-bravecto, .box-scalibor, .box-nobivac, .box-letifend, .box-bravecto_plus, .box-bravecto_enesimo {
        .logo img{
          width: 90%;
        }
      }
    }

    .content-top{
      .intro{
        // display: none;
      }
      .box-activity{
        width: 156px !important;
      }
    }
  }

  #statsForm select{
    width: 390px;
  }

}
@media screen and (max-width: 576px) {
  .navbar{
    .navbar-brand{
      margin-right: 0;
    }
    .navbar-nav{
      .nav-item{
        
        &.border-sides{
          border-left: 0;
          border-right: 0;
        }
        .nav-link{
          border-bottom: 1px solid $box_generico_light;
        }
      }
      .active{
        border-bottom: 0;
        border-left: 5px solid $verde;
        border-right: 5px solid $verde;
      }
    }
  }
  .modal-dialog{
    margin-top: 1rem;
  }
}
.fade>:not(.popover-body){
  background-color: rgba(0,0,0,0.8);
}
#modalRequired.fade .modal-dialog{
  background-color: transparent;
}
.popover{
  z-index: 9999999;
}