/**********************************************************************************************
                                       Pagina FAQ
**********************************************************************************************/

.accordion-button:not(.collapsed){
    background-color: #f5f5f5;
    color: #333333;
    border-color: #ddd;
    &::after{
        $accordion-button-color: #333333;
    }
}