@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.eot");
  src: url("../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.woff2") format("woff2"), url("../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/b6aeae6e9751258d6009b654c592c241.eot");
  src: url("../fonts/invention/b6aeae6e9751258d6009b654c592c241.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/b6aeae6e9751258d6009b654c592c241.woff2") format("woff2"), url("../fonts/invention/b6aeae6e9751258d6009b654c592c241.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/d14d33ff26cf1261579fbf28545982c9.eot");
  src: url("../fonts/invention/d14d33ff26cf1261579fbf28545982c9.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/d14d33ff26cf1261579fbf28545982c9.woff2") format("woff2"), url("../fonts/invention/d14d33ff26cf1261579fbf28545982c9.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.eot");
  src: url("../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.woff2") format("woff2"), url("../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.eot");
  src: url("../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.woff2") format("woff2"), url("../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/d6ba5077c0999241f6520681a41eac25.eot");
  src: url("../fonts/invention/d6ba5077c0999241f6520681a41eac25.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/d6ba5077c0999241f6520681a41eac25.woff2") format("woff2"), url("../fonts/invention/d6ba5077c0999241f6520681a41eac25.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.eot");
  src: url("../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.woff2") format("woff2"), url("../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/ba6ade37a504590140f80856e246cf2f.eot");
  src: url("../fonts/invention/ba6ade37a504590140f80856e246cf2f.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/ba6ade37a504590140f80856e246cf2f.woff2") format("woff2"), url("../fonts/invention/ba6ade37a504590140f80856e246cf2f.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.eot");
  src: url("../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.woff2") format("woff2"), url("../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.eot");
  src: url("../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.woff2") format("woff2"), url("../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.eot");
  src: url("../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.woff2") format("woff2"), url("../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Invention';
  src: url("../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.eot");
  src: url("../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.eot?#iefix") format("embedded-opentype"), url("../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.woff2") format("woff2"), url("../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/**********************************************************************************************
                                          Regole globali
**********************************************************************************************/
html, body {
  font-family: "Invention", Arial;
  font-size: 14.5px;
  color: #0C2340;
}

.table {
  color: #0C2340;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.text-justify {
  text-align: justify;
}

.form-label {
  margin-bottom: 0.2rem;
}

input[type=checkbox] {
  margin-right: 0.3rem;
}

.hide {
  display: none !important;
}

h3.section_title {
  color: #00857A;
  font-weight: 600;
}

/**********************************************************************************************
                                          Bottoni
**********************************************************************************************/
.btn {
  background-color: #00857A;
  border: none;
  border-bottom: 2px solid #00857C;
  width: 100%;
}

.btn.verde_scuro {
  background-color: #005463;
  color: #ffffff;
  border-bottom: none;
}

.container-fluid {
  padding: 0;
}

.navbar {
  background: #ffffff;
  border-bottom: 2px solid #005463;
}

.navbar .navbar-toggler {
  border-color: #00857A;
  color: #00857A;
}

.navbar .navbar-toggler.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 133, 124)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar .navbar-brand {
  border-right: 1px solid #c1c2c4;
}

.navbar .navbar-brand img {
  height: auto;
  max-height: 40px;
  width: auto;
}

.navbar .msd-small img {
  max-width: 80px;
}

.navbar .navbar-nav {
  line-height: 60px;
  text-align: center;
  padding-right: 0;
}

.navbar .navbar-nav a {
  color: #00857A;
}

.navbar .navbar-nav a:hover {
  text-decoration: none;
}

.navbar .navbar-nav .active {
  margin: 0;
  color: #00857A;
  font-weight: bolder;
  border-bottom: 5px solid #00857A;
}

.navbar .navbar-nav .active a {
  font-weight: bold;
}

.navbar .navbar-nav .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}

.navbar .navbar-nav .nav-item.border-sides {
  border-left: 1px solid #00857C;
  border-right: 1px solid #00857C;
  margin: 0;
  font-size: 12px;
}

.navbar .navbar-nav .nav-item.codice span {
  margin: 0;
}

.header_full {
  position: relative;
  background: #ffffff;
  border-bottom: 2px solid #005463;
  margin: 0;
}

.header_full .logo {
  margin-top: 5px;
  margin-bottom: 5px;
  border-right: 1px solid #c1c2c4;
  line-height: 60px;
}

.header_full .logo img {
  height: auto;
  max-height: 55px;
  width: auto;
}

.header_full .msd {
  line-height: 60px;
  text-align: right;
}

.header_full .top-right {
  line-height: 60px;
  text-align: center;
  padding-right: 0;
}

.header_full .top-right div {
  margin: 5px 0;
}

.header_full .top-right span {
  margin-left: 10px;
}

.header_full .top-right a {
  color: #00857A;
}

.header_full .top-right a:hover {
  text-decoration: none;
}

.header_full .top-right div.active {
  line-height: 65px;
  margin: 0;
  border-bottom: 5px solid #00857A;
}

.header_full .top-right div.active a {
  font-weight: bold;
}

.header_full .top-right .menu-item {
  float: right;
  padding-left: 15px;
  padding-right: 15px;
}

.header_full .top-right .menu-item.border-sides {
  border-left: 1px solid #00857C;
  border-right: 1px solid #00857C;
  margin: 0;
  line-height: 70px;
  font-size: 12px;
}

.header_full .top-right .menu-item.codice span {
  margin: 0;
}

.container-full {
  margin: 0;
}

.container-full.mt-3 {
  margin-top: 3em;
}

.container-full.footer_box_div {
  background-color: white;
  color: #0C2340;
  padding: 70px 30px;
}

.footer_box {
  background-color: #F1F1F2;
}

.footer_copyright {
  position: relative;
  padding: 20px;
  font-size: 10px;
  color: #0C2340;
}

.footer_privacy {
  position: relative;
  margin-bottom: 20px;
  font-size: 13px;
  color: #0C2340;
}

.footer_privacy a {
  color: #0C2340;
  text-decoration: none;
  cursor: pointer;
}

.footer_privacy a.msd-privacy {
  color: #00857A;
}

.footer_privacy a:hover {
  color: #00857A;
}

.footer_privacy a:not(:first-child) {
  margin-left: 20px;
}

.touch-area {
  display: grid;
  grid-template-areas: 'a b '  'bar bar'  'd c';
  grid: gap 10px;
  grid-gap: 10px;
}

@media screen and (max-width: 767px) {
  .touch-area {
    grid-template-areas: 'a' 'b' 'bar' 'd' 'c';
  }
}

.touch-bar {
  grid-area: bar;
}

.touch-a {
  grid-area: a;
}

.touch-b {
  grid-area: b;
}

.touch-c {
  grid-area: c;
}

@media (max-width: 767px) {
  .touch-c {
    width: 100%;
    margin-left: auto;
  }
}

.touch-d {
  grid-area: d;
}

@media (max-width: 767px) {
  .touch-d {
    margin-left: auto;
  }
}

@media (max-width: 1024px) {
  .header_full .top-right .menu-item {
    padding: 0 15px;
  }
  .header_full .logo img {
    height: auto;
    width: 100%;
  }
  .container-reminder .content-left .box-activity .type-activity img {
    width: 90%;
  }
  .container-reminder .content-reminder .box-bravecto .logo img, .container-reminder .content-reminder .box-scalibor .logo img, .container-reminder .content-reminder .box-nobivac .logo img, .container-reminder .content-reminder .box-letifend .logo img, .container-reminder .content-reminder .box-bravecto_plus .logo img, .container-reminder .content-reminder .box-bravecto_enesimo .logo img {
    width: 90%;
  }
  .container-reminder .content-top .box-activity {
    width: 156px !important;
  }
  #statsForm select {
    width: 390px;
  }
}

@media screen and (max-width: 576px) {
  .navbar .navbar-brand {
    margin-right: 0;
  }
  .navbar .navbar-nav .nav-item.border-sides {
    border-left: 0;
    border-right: 0;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    border-bottom: 1px solid #8F96A1;
  }
  .navbar .navbar-nav .active {
    border-bottom: 0;
    border-left: 5px solid #00857A;
    border-right: 5px solid #00857A;
  }
  .modal-dialog {
    margin-top: 1rem;
  }
}

.fade > :not(.popover-body) {
  background-color: rgba(0, 0, 0, 0.8);
}

#modalRequired.fade .modal-dialog {
  background-color: transparent;
}

.popover {
  z-index: 9999999;
}

.cb-slideshow {
  list-style: none;
}

.cb-slideshow, .cb-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.cb-fixed {
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.cb-fixed.bravecto {
  background-image: url(../images/fullscreen/bravecto.jpg);
}

.cb-fixed.bravecto_enesimo {
  background-image: url(../images/fullscreen/bravecto_enesimo.jpg);
}

.cb-fixed.bravecto_plus {
  background-image: url(../images/fullscreen/bravecto_plus.jpg);
}

.cb-fixed.scalibor {
  background-image: url(../images/fullscreen/scalibor.jpg);
}

.cb-fixed.nobivac {
  background-image: url(../images/fullscreen/nobivac.jpg);
}

.cb-fixed.letifend {
  background-image: url(../images/fullscreen/letifend.jpg);
}

.cb-fixed.geniki_therapeia {
  background-image: url(../images/fullscreen/geniki_therapeia.jpg);
}

.cb-fixed.epomeni_episkepsi {
  background-image: url(../images/fullscreen/epomeni_episkepsi.jpg);
}

/*
.cb-slideshow:after {
    content: '';
    background: transparent url(../images/pattern.png) repeat top left;
}
*/
.cb-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: imageAnimation 30s linear infinite 0s;
  -moz-animation: imageAnimation 30s linear infinite 0s;
  -o-animation: imageAnimation 30s linear infinite 0s;
  -ms-animation: imageAnimation 30s linear infinite 0s;
  animation: imageAnimation 30s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
  background-image: url(../images/fullscreen/rotate/2.jpg);
}

.cb-slideshow li:nth-child(2) span {
  background-image: url(../images/fullscreen/rotate/3.jpg);
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) span {
  background-image: url(../images/fullscreen/rotate/4.jpg);
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) span {
  background-image: url(../images/fullscreen/rotate/5.jpg);
  -webkit-animation-delay: 18s;
  -moz-animation-delay: 18s;
  -o-animation-delay: 18s;
  -ms-animation-delay: 18s;
  animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) span {
  background-image: url(../images/fullscreen/rotate/6.jpg);
  -webkit-animation-delay: 24s;
  -moz-animation-delay: 24s;
  -o-animation-delay: 24s;
  -ms-animation-delay: 24s;
  animation-delay: 24s;
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -moz-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -o-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -ms-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Show at least something when animations not supported */
.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

/**********************************************************************************************
                                       Pagina di Login
**********************************************************************************************/
.login_page {
  position: relative;
  padding: 30px 0 0 0;
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  top: 40px;
  left: 50%;
  margin-left: -250px;
  margin-bottom: 150px;
  margin-top: 50px;
}

.login_page button.btn {
  border-bottom: none;
}

.login_page .login_box {
  padding: 0 30px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.login_page .login_box h3 {
  color: #00857A;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
}

.login_page .login_box .intro {
  margin-bottom: 20px;
  font-weight: 600;
}

.login_page .login_box label {
  font-weight: 300;
}

.login_page .login_box .password-lost {
  text-align: right;
  color: #0C2340;
  text-decoration: underline;
}

.login_page .login_box .code-email {
  margin-bottom: 15px;
}

.login_page .login_registration_box {
  padding: 15px 30px;
  border-top: 1px solid #D4D7DB;
  border-bottom: 1px solid #D4D7DB;
}

.login_page .login_registration_box .form-group {
  margin-bottom: 10px;
}

.login_page .login_registration_box .text-center {
  font-weight: 600;
}

.login_page .header_box, .login_page .footer_box {
  padding: 30px 0;
}

@media screen and (max-width: 576px) {
  .login_page {
    position: unset;
    width: auto;
    margin: auto;
  }
}

/**********************************************************************************************
                                       Pagina di Registrazione
**********************************************************************************************/
.register_page {
  position: relative;
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  top: 40px;
  left: 50%;
  margin-left: -250px;
  margin-bottom: 150px;
  margin-top: 50px;
}

.register_page button.btn {
  border-bottom: none;
}

.register_page .header_box, .register_page .footer_box {
  padding-top: 30px;
}

.register_page h3 {
  color: #00857A;
  font-size: 18px;
  font-weight: 600;
}

.register_page label {
  font-weight: 300;
}

.register_page .checkbox label {
  color: #0C2340;
}

.register_page .checkbox label a {
  color: #0C2340;
  text-decoration: underline;
}

.register_page .checkbox label a strong {
  font-weight: bold;
}

.register_page .modal {
  z-index: 1070;
}

.register_page #ModalTermini .signature-container {
  padding: 6px 0;
  color: #E71B39;
  font-weight: 600;
}

.register_page #ModalTermini .form-control-feedback {
  right: 15px;
}

@media screen and (max-width: 576px) {
  .register_page {
    position: unset;
    width: auto;
    margin: auto;
  }
}

/**********************************************************************************************
                                       Home - Dashboard
**********************************************************************************************/
.fluid-reminder {
  position: relative;
  z-index: 9999999;
  background: -moz-linear-gradient(top, white 0%, white 23%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, white), color-stop(23%, white), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, white 0%, white 23%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, white 0%, white 23%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(top, white 0%, white 23%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, white 0%, white 23%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.container-reminder {
  padding-top: 20px;
}

.container-reminder .content-left {
  color: #0C2340;
  font-size: 14px;
}

.container-reminder .content-left .intro {
  text-align: center;
  margin-bottom: 10px;
}

.container-reminder .content-left .box-activity {
  background: #ffffff;
  margin-bottom: 5px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 115px;
}

.container-reminder .content-left .box-activity .number-activity {
  color: #243444;
  font-size: 36px;
  font-weight: 700;
}

.container-reminder .title-reminder {
  display: block;
  color: #0C2340;
  text-align: center;
  text-transform: none;
  position: relative;
  margin-top: 20px;
}

.container-reminder .title-reminder span {
  background: #fff;
  font-size: 18px;
  padding: 0 20px;
  position: relative;
  z-index: 5;
  font-family: 'Invention';
  font-weight: 700;
}

.container-reminder .title-reminder::before {
  content: "";
  display: block;
  border-top: solid 2px #8F96A1;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.container-reminder .content-reminder {
  background: #ffffff;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
}

.container-reminder .content-reminder .row {
  margin-left: auto;
  margin-right: auto;
}

.container-reminder .privacy_box {
  text-align: center;
  color: #0C2340;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.container-reminder .privacy_box a {
  color: #00857A;
  font-weight: bold;
}

.box-reminder {
  height: 96px;
}

.box-reminder .logo {
  text-align: center;
}

.box-reminder .testo {
  height: 100%;
  color: #ffffff;
  vertical-align: middle;
  font-size: 20px;
  display: flex;
}

.box-reminder.box-bravecto {
  border: 2px solid #4F2D80;
}

.box-reminder.box-bravecto .testo {
  background-color: #4F2D80;
}

.box-reminder.box-bravecto .testo {
  background: #4F2D80;
  background: -moz-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #7B62A0), color-stop(100%, #4F2D80), color-stop(100%, #4F2D80));
  background: -webkit-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -o-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -ms-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: linear-gradient(to bottom, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_light', endColorstr='$bravecto', GradientType=0);
}

.box-reminder.box-bravecto_enesimo {
  border: 2px solid #a17044;
}

.box-reminder.box-bravecto_enesimo .testo {
  background-color: #a17044;
}

.box-reminder.box-bravecto_enesimo .testo {
  background: #a17044;
  background: -moz-linear-gradient(top, #c78a54 0%, #a17044 100%, #a17044 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #c78a54), color-stop(100%, #a17044), color-stop(100%, #a17044));
  background: -webkit-linear-gradient(top, #c78a54 0%, #a17044 100%, #a17044 100%);
  background: -o-linear-gradient(top, #c78a54 0%, #a17044 100%, #a17044 100%);
  background: -ms-linear-gradient(top, #c78a54 0%, #a17044 100%, #a17044 100%);
  background: linear-gradient(to bottom, #c78a54 0%, #a17044 100%, #a17044 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_enesimo_light', endColorstr='$bravecto_enesimo', GradientType=0);
}

.box-reminder.box-bravecto_plus {
  border: 2px solid #E71B39;
}

.box-reminder.box-bravecto_plus .testo {
  background-color: #E71B39;
}

.box-reminder.box-bravecto_plus .testo {
  background: #E71B39;
  background: -moz-linear-gradient(top, #ff95a3 0%, #E71B39 100%, #E71B39 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ff95a3), color-stop(100%, #E71B39), color-stop(100%, #E71B39));
  background: -webkit-linear-gradient(top, #ff95a3 0%, #E71B39 100%, #E71B39 100%);
  background: -o-linear-gradient(top, #ff95a3 0%, #E71B39 100%, #E71B39 100%);
  background: -ms-linear-gradient(top, #ff95a3 0%, #E71B39 100%, #E71B39 100%);
  background: linear-gradient(to bottom, #ff95a3 0%, #E71B39 100%, #E71B39 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_plus_light', endColorstr='$bravecto_plus', GradientType=0);
}

.box-reminder.box-scalibor {
  border: 2px solid #FAAF40;
}

.box-reminder.box-scalibor .testo {
  background: #FAAF40;
}

.box-reminder.box-scalibor .testo {
  background: #FAAF40;
  background: -moz-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #FBBE65), color-stop(100%, #FAAF40), color-stop(100%, #FAAF40));
  background: -webkit-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -o-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -ms-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: linear-gradient(to bottom, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
}

.box-reminder.box-nobivac {
  border: 2px solid #243444;
}

.box-reminder.box-nobivac .testo {
  background: #243444;
  background: -moz-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #65737F), color-stop(100%, #243444), color-stop(100%, #243444));
  background: -webkit-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -o-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -ms-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: linear-gradient(to bottom, #65737F 0%, #243444 100%, #243444 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$nobivac', GradientType=0);
}

.box-reminder.box-letifend {
  border: 2px solid #5B8FC5;
}

.box-reminder.box-letifend .testo {
  background: #5B8FC5;
  background: -moz-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #65737F), color-stop(100%, #5B8FC5), color-stop(100%, #5B8FC5));
  background: -webkit-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -o-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -ms-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: linear-gradient(to bottom, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$letifend_light', endColorstr='$letifend', GradientType=0);
}

.box-reminder.box-visita {
  border: 2px solid #009B97;
}

.box-reminder.box-visita .logo {
  padding: 0px;
  height: 100%;
}

.box-reminder.box-visita .logo img {
  width: 100%;
  height: 100%;
}

.box-reminder.box-visita .testo {
  background: #009B97;
  background: -moz-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #54BAB9), color-stop(100%, #009B97), color-stop(100%, #009B97));
  background: -webkit-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -o-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -ms-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: linear-gradient(to bottom, #54BAB9 0%, #009B97 100%, #009B97 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
}

.box-reminder.box-generico {
  border: 2px solid #65707E;
}

.box-reminder.box-generico .logo {
  padding: 0px;
  height: 100%;
}

.box-reminder.box-generico .logo img {
  width: 100%;
  height: 100%;
}

.box-reminder.box-generico .testo {
  background: #009B97;
  background: -moz-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #8F96A1), color-stop(100%, #65707E), color-stop(100%, #65707E));
  background: -webkit-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -o-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -ms-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: linear-gradient(to bottom, #8F96A1 0%, #65707E 100%, #65707E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
}

.box-special-message {
  border: 2px solid #339019;
}

.box-special-message .logo {
  padding: 0px !important;
  height: 100%;
}

.box-special-message .logo img {
  width: 100%;
  height: 100%;
}

.box-special-message .testo {
  color: white !important;
  background: #009B97;
  background: -moz-linear-gradient(top, #A1CC59 0%, #339019 100%, #339019 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #A1CC59), color-stop(100%, #339019), color-stop(100%, #339019));
  background: -webkit-linear-gradient(top, #A1CC59 0%, #339019 100%, #339019 100%);
  background: -o-linear-gradient(top, #A1CC59 0%, #339019 100%, #339019 100%);
  background: -ms-linear-gradient(top, #A1CC59 0%, #339019 100%, #339019 100%);
  background: linear-gradient(to bottom, #A1CC59 0%, #339019 100%, #339019 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
}

@media screen and (max-width: 576px) {
  .container-reminder .privacy_box {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .container-reminder .content-left .box-activity .number-activity {
    font-size: 2rem;
  }
  .container-reminder .box-reminder .testo {
    font-size: 1.25rem;
    line-height: normal;
    display: flex;
  }
  .container-reminder .box-reminder .logo {
    display: flex;
  }
  .container-reminder .box-reminder .logo img {
    height: auto !important;
  }
}

/**********************************************************************************************
                                       Pagina di Registrazione
**********************************************************************************************/
.reminder_page {
  position: relative;
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  margin-top: 40px;
  left: 50%;
  margin-left: -250px;
}

.reminder_page .form-telephone-control {
  display: flex;
  align-items: center;
  max-width: 300px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.reminder_page .form-telephone-control:focus-within {
  background-color: #fff;
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.reminder_page .form-telephone-control .prefix {
  font-weight: 300;
  color: #999;
}

.reminder_page .form-telephone-control input {
  flex-grow: 1;
  border: none;
  outline: none;
}

.reminder_page .form-telephone-control input:focus {
  border: none;
  box-shadow: none;
}

.reminder_page .multi-reminder {
  font-size: 0.9rem;
}

.reminder_page .multi-reminder .col-3.datapicker, .reminder_page .multi-reminder .col-4.datapicker {
  display: inline-flex;
}

.reminder_page input[type=radio], .reminder_page input[type=checkbox] {
  margin-right: 0.5rem;
}

.reminder_page input[type=radio].chk_reminder_multi, .reminder_page input[type=checkbox].chk_reminder_multi {
  margin-right: 0.2rem;
}

.reminder_page .panel-heading {
  padding: 20px 15px;
}

.reminder_page .btn.cancel {
  color: #fff;
}

.reminder_page .btn_animals {
  background-color: #F1F1F2;
  width: 70px;
  height: 70px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  border: none;
}

.reminder_page .btn_animals span {
  display: block;
  text-align: center;
  margin-top: 70px;
}

.reminder_page .btn_dog {
  background: #F1F1F2 url("../images/animals/dog.png") no-repeat center;
}

.reminder_page .btn_cat {
  background: #F1F1F2 url("../images/animals/cat.png") no-repeat center;
}

.reminder_page .btn_rabbit {
  background: #F1F1F2 url("../images/animals/rabbit.png") no-repeat center;
}

.reminder_page .bravecto .intro-bravecto {
  margin-bottom: 10px;
}

.reminder_page .bravecto .intro-bravecto .radio-inline {
  padding-top: 0;
}

.reminder_page .bravecto .intro-bravecto .radio-inline label {
  font-weight: 300;
}

.reminder_page .bravecto .btn_dog.active {
  background-color: #E71B39;
  background: url("../images/animals/dog_active.png") no-repeat center;
  background: url("../images/animals/dog_active.png") no-repeat center, -moz-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-gradient(left top, left bottom, color-stop(0%, #E71B39), color-stop(80%, #4F2D80), color-stop(100%, #4F2D80));
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -o-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -ms-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, linear-gradient(to bottom, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto', GradientType=0);
}

.reminder_page .bravecto .btn_cat.active {
  background-color: #E71B39;
  background: url("../images/animals/cat_active.png") no-repeat center;
  background: url("../images/animals/cat_active.png") no-repeat center, -moz-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -webkit-gradient(left top, left bottom, color-stop(0%, #E71B39), color-stop(80%, #4F2D80), color-stop(100%, #4F2D80));
  background: url("../images/animals/cat_active.png") no-repeat center, -webkit-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -o-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -ms-linear-gradient(top, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, linear-gradient(to bottom, #E71B39 0%, #4F2D80 80%, #4F2D80 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto', GradientType=0);
}

.reminder_page .bravecto .btn.cancel {
  background-color: #4F2D80;
  border-bottom: 2px solid #2c003a;
}

.reminder_page .bravecto .btn.btn-primary {
  background-color: #E71B39;
  border-bottom: 2px solid #bb0009;
}

.reminder_page .bravecto .multi-reminder {
  background-color: #4F2D80;
  margin: 0 -16px !important;
  padding: 5px 16px;
}

.reminder_page .bravecto .multi-reminder .reminder_label {
  color: #ffffff;
  padding: 0 0 0 24px;
}

.reminder_page .bravecto_plus .intro-bravecto_plus {
  margin-bottom: 10px;
}

.reminder_page .bravecto_plus .intro-bravecto_plus .radio-inline {
  padding-top: 0;
}

.reminder_page .bravecto_plus .intro-bravecto_plus .radio-inline label {
  font-weight: 300;
}

.reminder_page .bravecto_plus .btn_cat.active {
  background-color: #E71B39;
  background: url("../images/animals/cat_active.png") no-repeat center;
  background: url("../images/animals/cat_active.png") no-repeat center, -moz-linear-gradient(top, #E71B39 0%, #E71B39 80%, #E71B39 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -webkit-gradient(left top, left bottom, color-stop(0%, #E71B39), color-stop(80%, #E71B39), color-stop(100%, #E71B39));
  background: url("../images/animals/cat_active.png") no-repeat center, -webkit-linear-gradient(top, #E71B39 0%, #E71B39 80%, #E71B39 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -o-linear-gradient(top, #E71B39 0%, #E71B39 80%, #E71B39 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, -ms-linear-gradient(top, #E71B39 0%, #E71B39 80%, #E71B39 100%);
  background: url("../images/animals/cat_active.png") no-repeat center, linear-gradient(to bottom, #E71B39 0%, #E71B39 80%, #E71B39 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto_plus', GradientType=0);
}

.reminder_page .bravecto_plus .btn.cancel {
  background-color: #4F2D80;
  border-bottom: 2px solid #4F2D80;
}

.reminder_page .bravecto_plus .btn.btn-primary {
  background-color: #E71B39;
  border-bottom: 2px solid #bb0009;
}

.reminder_page .bravecto_plus .multi-reminder {
  background-color: #E71B39;
  margin: 0 -16px !important;
  padding: 5px 16px;
}

.reminder_page .bravecto_plus .multi-reminder .reminder_label {
  color: #ffffff;
  padding: 0 0 0 24px;
}

.reminder_page .bravecto_enesimo .intro-bravecto_enesimo {
  margin-bottom: 10px;
}

.reminder_page .bravecto_enesimo .intro-bravecto_enesimo .radio-inline {
  padding-top: 0;
}

.reminder_page .bravecto_enesimo .intro-bravecto_enesimo .radio-inline label {
  font-weight: 300;
}

.reminder_page .bravecto_enesimo .btn_dog.active {
  background-color: #c78a54;
  background: url("../images/animals/dog_active.png") no-repeat center;
  background: url("../images/animals/dog_active.png") no-repeat center, -moz-linear-gradient(top, #c78a54 0%, #a17044 80%, #a17044 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-gradient(left top, left bottom, color-stop(0%, #c78a54), color-stop(80%, #a17044), color-stop(100%, #a17044));
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-linear-gradient(top, #c78a54 0%, #a17044 80%, #a17044 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -o-linear-gradient(top, #c78a54 0%, #a17044 80%, #a17044 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -ms-linear-gradient(top, #c78a54 0%, #a17044 80%, #a17044 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, linear-gradient(to bottom, #c78a54 0%, #a17044 80%, #a17044 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_enesimo_start', endColorstr='$bravecto_enesimo', GradientType=0);
}

.reminder_page .bravecto_enesimo .btn.cancel {
  background-color: #4F2D80;
  border-bottom: 2px solid #4F2D80;
}

.reminder_page .bravecto_enesimo .btn.btn-primary {
  background-color: #a17044;
  border-bottom: 2px solid #a17044;
}

.reminder_page .bravecto_enesimo .multi-reminder {
  background-color: #a17044;
  margin: 0 -16px !important;
  padding: 5px 16px;
}

.reminder_page .bravecto_enesimo .multi-reminder .reminder_label {
  color: #ffffff;
  padding: 0 0 0 24px;
}

.reminder_page .bravecto_enesimo .multi-reminder .datapicker input[type=text].form-control {
  width: auto;
  display: inline;
}

.reminder_page .scalibor .btn_dog.active {
  background-color: #FBBE65;
  background: url("../images/animals/dog_active.png") no-repeat center;
  background: url("../images/animals/dog_active.png") no-repeat center, -moz-linear-gradient(top, #FBBE65 0%, #FAAF40 80%, #FAAF40 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-gradient(left top, left bottom, color-stop(0%, #FBBE65), color-stop(80%, #FAAF40), color-stop(100%, #FAAF40));
  background: url("../images/animals/dog_active.png") no-repeat center, -webkit-linear-gradient(top, #FBBE65 0%, #FAAF40 80%, #FAAF40 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -o-linear-gradient(top, #FBBE65 0%, #FAAF40 80%, #FAAF40 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, -ms-linear-gradient(top, #FBBE65 0%, #FAAF40 80%, #FAAF40 100%);
  background: url("../images/animals/dog_active.png") no-repeat center, linear-gradient(to bottom, #FBBE65 0%, #FAAF40 80%, #FAAF40 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
}

.reminder_page .scalibor .btn.cancel {
  background-color: #243444;
  border-bottom: 2px solid #000;
}

.reminder_page .scalibor .btn.btn-primary {
  background-color: #FAAF40;
  border-bottom: 2px solid #c97a00;
}

.reminder_page .scalibor .multi-reminder {
  background-color: #FAAF40;
  margin: 0 -16px !important;
  padding: 5px 16px;
}

.reminder_page .scalibor .multi-reminder .reminder_label {
  color: #ffffff;
  padding: 0 0 0 24px;
}

.reminder_page .nobivac .multi-date {
  position: relative;
  margin-bottom: 20px;
}

.reminder_page .nobivac .info_text {
  font-size: 13px;
}

.reminder_page .nobivac .btn_dog.active {
  background: url("../images/animals/dog_active.png") no-repeat center #1a171b;
}

.reminder_page .nobivac .btn_cat.active {
  background: url("../images/animals/cat_active.png") no-repeat center #1a171b;
}

.reminder_page .nobivac .btn_rabbit.active {
  background: url("../images/animals/rabbit_active.png") no-repeat center #1a171b;
}

.reminder_page .nobivac .btn.cancel {
  background-color: #1a171b;
  border-bottom: 2px solid #000;
}

.reminder_page .nobivac .btn.btn-primary {
  background-color: #0069b1;
  border-bottom: 2px solid #003477;
}

.reminder_page .letifend .multi-date {
  position: relative;
  margin-bottom: 20px;
}

.reminder_page .letifend .info_text {
  font-size: 13px;
}

.reminder_page .letifend .btn_dog.active {
  background: url("../images/animals/dog_active.png") no-repeat center #1a171b;
}

.reminder_page .letifend .btn_cat.active {
  background: url("../images/animals/cat_active.png") no-repeat center #1a171b;
}

.reminder_page .letifend .btn_rabbit.active {
  background: url("../images/animals/rabbit_active.png") no-repeat center #1a171b;
}

.reminder_page .letifend .btn.cancel {
  background-color: #1a171b;
  border-bottom: 2px solid #000;
}

.reminder_page .letifend .btn.btn-primary {
  background-color: #0069b1;
  border-bottom: 2px solid #003477;
}

.reminder_page .epomeni_episkepsi .multi-date {
  position: relative;
  margin-bottom: 20px;
}

.reminder_page .epomeni_episkepsi .btn_dog.active {
  background: url("../images/animals/dog_active.png") no-repeat center #00857A;
}

.reminder_page .epomeni_episkepsi .btn_cat.active {
  background: url("../images/animals/cat_active.png") no-repeat center #00857A;
}

.reminder_page .epomeni_episkepsi .btn_rabbit.active {
  background: url("../images/animals/rabbit_active.png") no-repeat center #00857A;
}

.reminder_page .epomeni_episkepsi .btn.cancel {
  background-color: #005463;
  border-bottom: 2px solid #243444;
}

.reminder_page .epomeni_episkepsi .btn.btn-primary {
  background-color: #00857A;
  border-bottom: 2px solid #005463;
}

.reminder_page .geniki_therapeia .btn_dog.active {
  background: url("../images/animals/dog_active.png") no-repeat center #243444;
}

.reminder_page .geniki_therapeia .btn_cat.active {
  background: url("../images/animals/cat_active.png") no-repeat center #243444;
}

.reminder_page .geniki_therapeia .btn_rabbit.active {
  background: url("../images/animals/rabbit_active.png") no-repeat center #243444;
}

.reminder_page .geniki_therapeia .btn.cancel {
  background-color: #005463;
  border-bottom: 2px solid #243444;
}

.reminder_page .geniki_therapeia .btn.btn-primary {
  background-color: #00857A;
  border-bottom: 2px solid #005463;
}

.reminder_page .row.col4, .reminder_page .row.col3 {
  margin-left: -7px;
  margin-right: -7px;
}

.reminder_page .row.col4 .col-sm-3.datapicker, .reminder_page .row.col4 .col-sm-4.datapicker, .reminder_page .row.col3 .col-sm-3.datapicker, .reminder_page .row.col3 .col-sm-4.datapicker {
  padding: 5px;
}

.reminder_page .row.col4 .col-sm-3.datapicker .form-control, .reminder_page .row.col4 .col-sm-4.datapicker .form-control, .reminder_page .row.col3 .col-sm-3.datapicker .form-control, .reminder_page .row.col3 .col-sm-4.datapicker .form-control {
  width: 90px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.reminder_page .row.col4 .col-sm-3.datapicker .chk_reminder_multi, .reminder_page .row.col4 .col-sm-4.datapicker .chk_reminder_multi, .reminder_page .row.col3 .col-sm-3.datapicker .chk_reminder_multi, .reminder_page .row.col3 .col-sm-4.datapicker .chk_reminder_multi {
  display: inline-block;
}

.reminder_page .reminder_multi:disabled {
  background-color: #cccccc;
}

.reminder_page .modal-header {
  padding-bottom: 0;
  border-bottom: none;
}

.reminder_page .nav-link {
  color: #0C2340;
}

.reminder_page .nav-tabs .nav-item.show .nav-link, .reminder_page .nav-tabs .nav-link.active {
  color: #00857A;
}

.reminder_page #open-preview {
  background: url("../images/reminder/bt_anteprima.png") no-repeat top center;
  width: 100px;
  height: 72px;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
}

.reminder_page #open-preview div {
  position: absolute;
  bottom: 0;
  text-transform: uppercase;
  font-size: 0.7em;
}

.reminder_page #open-preview:hover {
  background: url("../images/reminder/bt_anteprima_over.png") no-repeat top center;
}

.reminder_page .sms_bkg {
  background: url("../images/reminder/phone.png") no-repeat center;
  height: 465px;
}

.reminder_page .sms_bkg #sms_text {
  padding: 0;
  position: absolute;
  top: 355px;
  left: 275px;
  width: 300px;
  text-align: left;
  font-size: 17px;
}

.special_messages h2 {
  text-align: center;
  text-transform: uppercase;
  color: #00857A;
}

.special_messages label {
  font-weight: bold;
}

.special_messages .form-control[disabled], .special_messages .form-control[readonly], .special_messages fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.special_messages .covid_message_counter {
  padding-bottom: 10px;
}

.special_messages .legenda {
  color: #a5a6a8;
}

.special_messages .btn.cancel {
  background-color: #005463;
  border-bottom: 2px solid #243444;
}

@media screen and (max-width: 576px) {
  .reminder_page {
    left: auto;
    width: auto;
    margin: auto;
  }
  .reminder_page .btn_animals {
    width: 50px;
    height: 50px;
    background-size: initial;
  }
  .reminder_page .multi-reminder {
    font-size: 0.8rem;
  }
  .reminder_page .multi-reminder .col-3.datapicker, .reminder_page .multi-reminder .col-4.datapicker {
    text-align: center;
    margin-top: 0.5rem;
  }
  .reminder_page .multi-reminder .col-3.datapicker input, .reminder_page .multi-reminder .col-4.datapicker input {
    font-size: 0.8rem;
    padding: 0.35rem;
  }
  #open-preview {
    display: none;
  }
}

/*!
 * Datepicker for Bootstrap v1.6.4 (https://github.com/eternicode/bootstrap-datepicker)
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15);
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #777777;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #eeeeee;
  cursor: pointer;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}

.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0;
}

.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2;
}

.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}

.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}

.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2;
}

.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td.highlighted.focused {
  background: #afd9ee;
}

.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777777;
}

.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400;
}

.datepicker table tr td.today:hover {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today.active {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}

.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400;
}

.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today.focused {
  background: #ffc966;
}

.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #777777;
}

.datepicker table tr td.range {
  color: #000;
  background-color: #eeeeee;
  border-color: #bbbbbb;
  border-radius: 0;
}

.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c;
}

.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}

.datepicker table tr td.range:active,
.datepicker table tr td.range.active {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}

.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c;
}

.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}

.datepicker table tr td.range.focused {
  background: #d5d5d5;
}

.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #eeeeee;
  color: #777777;
}

.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6;
}

.datepicker table tr td.range.highlighted:hover {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}

.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}

.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6;
}

.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted.focused {
  background: #c1d7e3;
}

.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777777;
}

.datepicker table tr td.range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608;
}

.datepicker table tr td.range.today:hover {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}

.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608;
}

.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #f7ca77;
  color: #777777;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777777;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616;
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}

.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616;
}

.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #777777;
  border-color: #555555;
}

.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eeeeee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #777777;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eeeeee;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  text-shadow: 0 1px 0 #fff;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}

/*# sourceMappingURL=bootstrap-datepicker3.css.map */
/**********************************************************************************************
                                              STATS
**********************************************************************************************/
#statsForm {
  margin-top: 10px;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.remaining_special_messages {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 13px;
}

.container-reminder .row {
  margin-left: auto;
  margin-right: auto;
}

.container-reminder * {
  box-sizing: border-box;
}

.container-reminder #stats select {
  width: 130px;
}

.container-reminder .confirmation-buttons .btn {
  width: auto;
  border: 1px solid #adadad;
}

.container-reminder .confirmation-buttons .btn.btn-default {
  background-color: #ffffff;
}

.container-reminder .confirmation-buttons .btn.btn-default:hover {
  background-color: #e6e6e6;
}

.container-reminder .table > thead > tr > th {
  vertical-align: top;
  text-align: center;
}

.container-reminder .table > thead > tr > th select {
  background-color: white;
}

.container-reminder .content-top {
  color: #fff;
  background-color: #54BAB9;
  font-size: 14px;
  padding: 0;
}

.container-reminder .content-top .intro {
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.container-reminder .content-top .intro::before {
  vertical-align: middle;
  content: '';
  height: 44%;
  display: block;
}

.container-reminder .content-top .box-activity {
  background: #ffffff;
  margin: 5px 0px 5px 5px;
  text-align: center;
  padding-top: 10px;
  height: 100px;
  max-width: 140px;
  float: left;
  position: relative;
}

.container-reminder .content-top .box-activity .type-activity {
  height: 50px;
}

.container-reminder .content-top .box-activity .number-activity {
  color: #243444;
  font-size: 36px;
  font-weight: 700;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.container-reminder .content-top .box-activity.scalibor {
  padding-top: 5px;
}

.container-reminder .content-top .box-activity.nobivac, .container-reminder .content-top .box-activity.letifend {
  padding-top: 17px;
}

.container-reminder .title-reminder {
  display: block;
  color: #0C2340;
  text-align: center;
  text-transform: none;
  position: relative;
  margin-top: 20px;
}

.container-reminder .title-reminder span {
  background: #fff;
  font-size: 18px;
  padding: 0 20px;
  position: relative;
  z-index: 5;
  font-family: 'Invention';
  font-weight: 700;
}

.container-reminder .title-reminder::before {
  content: "";
  display: block;
  border-top: solid 2px #8F96A1;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.container-reminder .content-reminder.content-stats {
  background: #ffffff;
  padding: 0;
  position: relative;
}

.container-reminder .content-reminder.content-stats .table thead {
  background-color: #005463;
  color: #c4e3e2;
}

.container-reminder .content-reminder.content-stats .table > thead > tr > th {
  padding-bottom: 0;
}

.container-reminder .content-reminder.content-stats .table-bordered > thead > tr > th, .container-reminder .content-reminder.content-stats .table-bordered > thead > tr > td, .container-reminder .content-reminder.content-stats .table-bordered > tbody > tr > th, .container-reminder .content-reminder.content-stats .table-bordered > tbody > tr > td, .container-reminder .content-reminder.content-stats .table-bordered > tfoot > tr > th, .container-reminder .content-reminder.content-stats .table-bordered > tfoot > tr > td {
  border: 1px solid #54BAB9;
}

.container-reminder table.dataTable thead th, .container-reminder table.dataTable thead td {
  padding: 10px;
}

.container-reminder .dataTables_wrapper .dataTables_paginate {
  margin-bottom: 20px;
}

.container-reminder .dataTables_length, .container-reminder .dataTables_filter {
  margin: 10px;
}

.box-reminder {
  height: 96px;
}

.box-reminder .logo {
  text-align: center;
}

.box-reminder .testo {
  height: 100%;
  color: #ffffff;
  vertical-align: middle;
  display: flex;
}

.box-reminder.box-bravecto {
  border: 2px solid #4F2D80;
}

.box-reminder.box-bravecto .testo {
  background-color: #4F2D80;
}

.box-reminder.box-bravecto .testo {
  background: #4F2D80;
  background: -moz-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #7B62A0), color-stop(100%, #4F2D80), color-stop(100%, #4F2D80));
  background: -webkit-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -o-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: -ms-linear-gradient(top, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  background: linear-gradient(to bottom, #7B62A0 0%, #4F2D80 100%, #4F2D80 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_light', endColorstr='$bravecto', GradientType=0);
}

.box-reminder.box-scalibor {
  border: 2px solid #FAAF40;
}

.box-reminder.box-scalibor .testo {
  background: #FAAF40;
}

.box-reminder.box-scalibor .testo {
  background: #FAAF40;
  background: -moz-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #FBBE65), color-stop(100%, #FAAF40), color-stop(100%, #FAAF40));
  background: -webkit-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -o-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: -ms-linear-gradient(top, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  background: linear-gradient(to bottom, #FBBE65 0%, #FAAF40 100%, #FAAF40 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
}

.box-reminder.box-nobivac {
  border: 2px solid #243444;
}

.box-reminder.box-nobivac .testo {
  background: #243444;
  background: -moz-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #65737F), color-stop(100%, #243444), color-stop(100%, #243444));
  background: -webkit-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -o-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: -ms-linear-gradient(top, #65737F 0%, #243444 100%, #243444 100%);
  background: linear-gradient(to bottom, #65737F 0%, #243444 100%, #243444 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$nobivac', GradientType=0);
}

.box-reminder.box-letifend {
  border: 2px solid #5B8FC5;
}

.box-reminder.box-letifend .testo {
  background: #5B8FC5;
  background: -moz-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #65737F), color-stop(100%, #5B8FC5), color-stop(100%, #5B8FC5));
  background: -webkit-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -o-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: -ms-linear-gradient(top, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  background: linear-gradient(to bottom, #65737F 0%, #5B8FC5 100%, #5B8FC5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$letifend_light', endColorstr='$letifend', GradientType=0);
}

.box-reminder.box-visita {
  border: 2px solid #009B97;
}

.box-reminder.box-visita .logo {
  padding: 0px;
  height: 100%;
}

.box-reminder.box-visita .logo img {
  width: 100%;
  height: 100%;
}

.box-reminder.box-visita .testo {
  background: #009B97;
  background: -moz-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #54BAB9), color-stop(100%, #009B97), color-stop(100%, #009B97));
  background: -webkit-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -o-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: -ms-linear-gradient(top, #54BAB9 0%, #009B97 100%, #009B97 100%);
  background: linear-gradient(to bottom, #54BAB9 0%, #009B97 100%, #009B97 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
}

.box-reminder.box-generico {
  border: 2px solid #65707E;
}

.box-reminder.box-generico .logo {
  padding: 0px;
  height: 100%;
}

.box-reminder.box-generico .logo img {
  width: 100%;
  height: 100%;
}

.box-reminder.box-generico .testo {
  background: #009B97;
  background: -moz-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #8F96A1), color-stop(100%, #65707E), color-stop(100%, #65707E));
  background: -webkit-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -o-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: -ms-linear-gradient(top, #8F96A1 0%, #65707E 100%, #65707E 100%);
  background: linear-gradient(to bottom, #8F96A1 0%, #65707E 100%, #65707E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
}

.covid_link {
  color: #00857A;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .container-reminder .content-top .box-activity {
    max-width: 200px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .container-reminder .content-top .box-activity {
    max-width: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 992px) {
  .remaining_special_messages {
    position: unset;
    padding: 1rem 0;
  }
  .container.stats-container {
    max-width: 100%;
    padding: 0 1.2rem !important;
  }
  .container.stats-container table > * {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .container.stats-container {
    padding: 0 !important;
  }
  #statsForm {
    padding-left: 1.5rem;
  }
  #stats_wrapper {
    padding-bottom: 1rem;
  }
  .stats-box {
    padding: 0;
  }
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

table.dataTable thead th, table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable thead th, table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

table.dataTable thead th:active, table.dataTable thead td:active {
  outline: none;
}

table.dataTable tfoot th, table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png");
}

table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png");
}

table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png");
}

table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png");
}

table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png");
}

table.dataTable tbody tr {
  background-color: #ffffff;
}

table.dataTable tbody tr.selected {
  background-color: #B0BED9;
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th, table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th, table.dataTable.display tbody tr:first-child td {
  border-top: none;
}

table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child, table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th, table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4;
}

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1;
}

table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3 {
  background-color: #fafafa;
}

table.dataTable.order-column tbody tr.selected > .sorting_1, table.dataTable.order-column tbody tr.selected > .sorting_2, table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1, table.dataTable.display tbody tr.selected > .sorting_2, table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad5;
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}

table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3;
}

table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}

table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd;
}

table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf;
}

table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1;
}

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fafafa;
}

table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fcfcfc;
}

table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fefefe;
}

table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad5;
}

table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #aebcd6;
}

table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea;
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ececec;
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #efefef;
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a2aec7;
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a3b0c9;
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a5b2cb;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #111;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable.compact thead th, table.dataTable.compact thead td {
  padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th, table.dataTable.compact tfoot td {
  padding: 4px;
}

table.dataTable.compact tbody th, table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable, table.dataTable th, table.dataTable td {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: #333;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead > table, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}

/**********************************************************************************************
                                       Pagina FAQ
**********************************************************************************************/
.accordion-button:not(.collapsed) {
  background-color: #f5f5f5;
  color: #333333;
  border-color: #ddd;
}

/**********************************************************************************************
                                       Popup inserimento reminder aggiuntivi
**********************************************************************************************/
#more-reminder-container div.modal-content {
  padding: 0 0 2rem 0;
}

#more-reminder-container h4.modal-title {
  font-weight: bold;
  margin-bottom: 3rem;
}

button.btn.btn-next {
  border-bottom: 0;
  background-color: #fff;
  border: 2px solid;
  margin: 1rem auto;
  font-weight: bold;
}

button.btn.btn-next.bravecto {
  border-color: #4e2d7c;
  color: #4e2d7c;
}

button.btn.btn-next.bravecto_enesimo {
  border-color: #a17044;
  color: #a17044;
}

button.btn.btn-next.scalibor {
  border-color: #f9b041;
  color: #f9b041;
}

button.btn.btn-next.bravecto_plus {
  border-color: #e71b39;
  color: #e71b39;
}

button.btn.btn-next.nobivac {
  border-color: #5B8FC5;
  color: #5B8FC5;
}

button.btn.btn-next.letifend {
  border-color: #243444;
  color: #243444;
}

button.btn.btn-next.epomeni_episkepsi {
  border-color: #009b97;
  color: #009b97;
}

button.btn.btn-next.geniki_therapeia {
  border-color: #65707e;
  color: #65707e;
}

button.btn.btn-next.bravecto.active {
  background-color: #4e2d7c;
  color: #fff;
}

button.btn.btn-next.scalibor.active {
  background-color: #f9b041;
  color: #fff;
}

button.btn.btn-next.bravecto_plus.active {
  background-color: #e71b39;
  color: #fff;
}

button.btn.btn-next.bravecto_enesimo.active {
  background-color: #a17044;
  color: #fff;
}

button.btn.btn-next.nobivac.active {
  background-color: #243444;
  color: #fff;
}

button.btn.btn-next.letifend.active {
  background-color: #5B8FC5;
  color: #fff;
}

button.btn.btn-next.epomeni_episkepsi.active {
  background-color: #009b97;
  color: #fff;
}

button.btn.btn-next.geniki_therapeia.active {
  background-color: #65707e;
  color: #fff;
}

#btn_cancel_more_reminder,
#btn_more_reminder {
  margin-top: 1rem;
  border-bottom: 0;
}

#btn_cancel_more_reminder {
  background-color: #005363;
}

#btn_more_reminder.btn-disabled {
  background-color: #d5d5d5;
  color: #fff;
}
