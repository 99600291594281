
// Body
$body-bg: #ffffff;

// colors
$verde: #00857A;
$verde_scuro: #005463;
$verde_border: #00857C;
$grigio: #D4D7DB;
//Box home
$bravecto: #4F2D80;
$bravecto_enesimo: #a17044;
$bravecto_enesimo_light: #c78a54;
$bravecto_plus: #E71B39;
$bravecto_plus_light: #ff95a3;
$bravecto_light: #7B62A0;
$scalibor: #FAAF40;
$scalibor_light: #FBBE65;
$letifend: #5B8FC5;
$letifend_light: #65737F;
$nobivac: #243444;
$nobivac_light: #65737F;
$box_visita: #009B97;
$box_visita_light: #54BAB9;
$box_generico: #65707E;
$box_generico_light: #8F96A1;
$msd_blue_color: #0C2340;
$box_special_message: #339019;
$box_special_message_light: #A1CC59;

$bravecto_start: #E71B39;
$bravecto_enesimo_start: #c78a54;
$bg_animal: #F1F1F2;
$nobivac_animal: #1a171b;
$letifend_animal: #1a171b;

$footer_text: #0C2340;