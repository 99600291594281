
/**********************************************************************************************
                                              STATS
**********************************************************************************************/

#statsForm{
  margin-top: 10px;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}


.remaining_special_messages{
  text-align: center;
  position: absolute;
  width: 100%;
  top: 13px;
}

.container-reminder{
  .row{
    margin-left: auto;
    margin-right: auto;
  }
  *{
    box-sizing: border-box;
  }
  #stats select{
    width: 130px;
  }

  .confirmation-buttons .btn{
    width: auto;
    border:1px solid #adadad;

    &.btn-default{
      background-color: #ffffff;

      &:hover{
        background-color: #e6e6e6;
      }
    }
  }

  .table > thead > tr > th{
    vertical-align: top;
    text-align: center;

    select{
      background-color: white;
    }
  }
  .content-top{
    color: #fff;
    background-color: $box_visita_light;
    font-size: 14px;
    padding: 0;
    // display: table;

    .intro{
      text-align: center;
      margin-bottom: 10px;
      // float: left;
      // width: 290px;
      // height: 100px;
      //line-height: 100px;
      text-transform: uppercase;
    }
    .intro::before {
      vertical-align: middle;
      content: '';
      height: 44%;
      display: block;
    }

    .box-activity{
      background: $body-bg;
      margin: 5px 0px 5px 5px;
      text-align: center;
      padding-top: 10px;
      height: 100px;
      // width: 140px;
      max-width: 140px;
      float: left;
      position: relative;

      .type-activity{
        height:50px;
      }
      .number-activity{
        color: $nobivac;
        font-size: 36px;
        font-weight: 700;
        position: absolute;
        width: 100%;
        bottom: 0px;
      }
    }
    .box-activity.scalibor{
      padding-top: 5px;
    }
    .box-activity.nobivac,.box-activity.letifend{
      padding-top: 17px;
    }
  }

  .title-reminder{
    display: block;
    color: $msd_blue_color;
    text-align: center;
    text-transform: none;
    position: relative;
    margin-top: 20px;

    span {
      background: #fff;
      font-size: 18px;
      padding: 0 20px;
      position: relative;
      z-index: 5;
      font-family: 'Invention';
      font-weight: 700;
    }
  }
  .title-reminder::before {
    content: "";
    display: block;
    border-top: solid 2px $box_generico_light;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  .content-reminder.content-stats{
    background: $body-bg;
    padding: 0;
    position: relative;

    .table thead{
      background-color: $verde_scuro;
      color: #c4e3e2;
    }
    .table > thead > tr > th{
      padding-bottom: 0;
      //line-height: 30px;
    }

    .table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td{
      border: 1px solid $box_visita_light;
    }
  }
  table.dataTable thead th, table.dataTable thead td{
    padding: 10px;
  }
  .dataTables_wrapper .dataTables_paginate{
    margin-bottom: 20px;
  }
  .dataTables_length,.dataTables_filter{
    margin: 10px;
  }
}
.box-reminder{
  height: 96px;

  .logo{
    //height: 60px;
    // padding: 15px;
    text-align: center;
  }
  .testo{
    height: 100%;
    color: #ffffff;
    vertical-align: middle;
    // padding: 15px;
    // line-height: 60px;
    // line-height: normal;
        display: flex;
  }
}
.box-reminder.box-bravecto{
  border: 2px solid $bravecto;

  .testo{
    background-color: $bravecto;
  }
  .testo {
    background: $bravecto;
    background: -moz-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_light), color-stop(100%, $bravecto), color-stop(100%, $bravecto));
    background: -webkit-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -o-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -ms-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: linear-gradient(to bottom, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_light', endColorstr='$bravecto', GradientType=0);
  }

}
.box-reminder.box-scalibor{
  border: 2px solid $scalibor;

  .testo {
    background: $scalibor;
  }
  .testo {
    background: $scalibor;
    background: -moz-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $scalibor_light), color-stop(100%, $scalibor), color-stop(100%, $scalibor));
    background: -webkit-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -o-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -ms-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: linear-gradient(to bottom, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
  }

}
.box-reminder.box-nobivac {
  border: 2px solid $nobivac;
  .logo{
    // padding-top: 30px;
  }
  .testo {
    background: $nobivac;
    background: -moz-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $nobivac_light), color-stop(100%, $nobivac), color-stop(100%, $nobivac));
    background: -webkit-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -o-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -ms-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: linear-gradient(to bottom, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$nobivac', GradientType=0);
  }
}
.box-reminder.box-letifend {
  border: 2px solid $letifend;
  .logo{
    // padding-top: 30px;
  }
  .testo {
    background: $letifend;
    background: -moz-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $letifend_light), color-stop(100%, $letifend), color-stop(100%, $letifend));
    background: -webkit-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -o-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -ms-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: linear-gradient(to bottom, $letifend_light 0%, $letifend 100%, $letifend 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$letifend_light', endColorstr='$letifend', GradientType=0);
  }
}

.box-reminder.box-visita{
  border: 2px solid $box_visita;
  .logo{
    padding: 0px;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .testo {
    background: $box_visita;
    background: -moz-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $box_visita_light), color-stop(100%, $box_visita), color-stop(100%, $box_visita));
    background: -webkit-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -o-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -ms-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: linear-gradient(to bottom, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
  }
}

.box-reminder.box-generico{
  border: 2px solid $box_generico;
  .logo{
    padding: 0px;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .testo {
    background: $box_visita;
    background: -moz-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $box_generico_light), color-stop(100%, $box_generico), color-stop(100%, $box_generico));
    background: -webkit-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -o-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -ms-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: linear-gradient(to bottom, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
  }
}


.covid_link{
  color: $verde;
}
@media screen and (min-width:900px) and (max-width:1200px) {
  .container-reminder .content-top .box-activity{
    max-width: 200px;
  }
}
@media screen and (min-width:1200px) and (max-width:1400px) {
  .container-reminder .content-top .box-activity{
    max-width: 200px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 992px) {
  .remaining_special_messages{
    position: unset;
    padding: 1rem 0;
  }
  // .fluid-reminder{
  //   .row>*{
  //     margin-left: 0;
  //     margin-right: 0;
  //   }
  // }
  .container.stats-container{
    max-width: 100%;
    padding: 0 1.2rem !important;
    table>*{
      font-size: 0.8rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .container.stats-container{
    padding: 0 !important;
  }
  #statsForm{
    padding-left: 1.5rem;
  }
  #stats_wrapper{
    padding-bottom: 1rem;
  }
  .stats-box{
    padding: 0;
  }

}
