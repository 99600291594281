/**********************************************************************************************
                                       Pagina di Registrazione
**********************************************************************************************/

.reminder_page{
  $dog_active: url("../images/animals/dog_active.png") no-repeat center;
  $cat_active: url("../images/animals/cat_active.png") no-repeat center;
  $rabbit_active: url("../images/animals/rabbit_active.png") no-repeat center;

  position: relative;
  width: 500px;
  background: $body-bg;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  margin-top: 40px;
  left: 50%;
  margin-left: -250px;

  
  .form-telephone-control{
    display: flex;
    align-items: center;
    max-width: 300px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
    &:focus-within{
      background-color: #fff;
      border-color: #86b7fe;
      box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    }
    .prefix {
      font-weight: 300;
      color: #999;
    }

    input {
      flex-grow: 1;
      border: none;
      outline: none;
      &:focus{
        border: none;
        box-shadow: none;
      }
      // padding: 0.5rem;
    }

  }
  
.multi-reminder{
  font-size: 0.9rem;
  .col-3.datapicker,.col-4.datapicker{
    display: inline-flex;
  }
}
  input[type=radio], input[type=checkbox]{
    
    margin-right: 0.5rem;
    &.chk_reminder_multi{
      margin-right: 0.2rem;
    }
  }
  .panel-heading{
    padding: 20px 15px;
  }
  .btn.cancel{
    color: #fff;
  }

  .btn_animals{
    background-color: $bg_animal;
    width: 70px;
    height: 70px;
    -moz-border-radius: 35px;
    -webkit-border-radius: 35px;
    border-radius: 35px;
    border: none;

    span{
      display: block;
      text-align: center;
      margin-top: 70px;
    }
  }
  .btn_dog{
    background: $bg_animal url("../images/animals/dog.png") no-repeat center;
  }
  .btn_cat{
    background: $bg_animal url("../images/animals/cat.png") no-repeat center;
  }
  .btn_rabbit{
    background: $bg_animal url("../images/animals/rabbit.png") no-repeat center;
  }

  .bravecto{
    .intro-bravecto{
      margin-bottom: 10px;

      .radio-inline{
        padding-top: 0;
        label{
            font-weight: 300;
        }
      }
    }
    .btn_dog.active{
      background-color: $bravecto_start;
      background: $dog_active;
      background: $dog_active,-moz-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $dog_active,-webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_start), color-stop(80%, $bravecto), color-stop(100%, $bravecto));
      background: $dog_active,-webkit-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $dog_active,-o-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $dog_active,-ms-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $dog_active,linear-gradient(to bottom, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto', GradientType=0);
    }
    .btn_cat.active{
      background-color: $bravecto_start;
      background: $cat_active;
      background: $cat_active,-moz-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $cat_active,-webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_start), color-stop(80%, $bravecto), color-stop(100%, $bravecto));
      background: $cat_active,-webkit-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $cat_active,-o-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $cat_active,-ms-linear-gradient(top, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      background: $cat_active,linear-gradient(to bottom, $bravecto_start 0%, $bravecto 80%, $bravecto 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto', GradientType=0);
    }
    .btn.cancel{
      background-color: $bravecto;
      border-bottom: 2px solid #2c003a;
    }
    .btn.btn-primary{
      background-color: $bravecto_start;
      border-bottom: 2px solid #bb0009;
    }
    .multi-reminder{
      background-color: $bravecto;
      margin: 0 -16px !important;
      padding: 5px 16px;

      .reminder_label{
        color: #ffffff;
        padding: 0 0 0 24px;
      }
    }
    //.row.col4{
    //  background-color: $bravecto;
    //  margin: inherit -16px;
    //}

  }

  .bravecto_plus{
    .intro-bravecto_plus{
      margin-bottom: 10px;

      .radio-inline{
        padding-top: 0;
        label{
          font-weight: 300;
        }
      }
    }
    .btn_cat.active{
      background-color: $bravecto_start;
      background: $cat_active;
      background: $cat_active,-moz-linear-gradient(top, $bravecto_start 0%, $bravecto_plus 80%, $bravecto_plus 100%);
      background: $cat_active,-webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_start), color-stop(80%, $bravecto_plus), color-stop(100%, $bravecto_plus));
      background: $cat_active,-webkit-linear-gradient(top, $bravecto_start 0%, $bravecto_plus 80%, $bravecto_plus 100%);
      background: $cat_active,-o-linear-gradient(top, $bravecto_start 0%, $bravecto_plus 80%, $bravecto_plus 100%);
      background: $cat_active,-ms-linear-gradient(top, $bravecto_start 0%, $bravecto_plus 80%, $bravecto_plus 100%);
      background: $cat_active,linear-gradient(to bottom, $bravecto_start 0%, $bravecto_plus 80%, $bravecto_plus 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_start', endColorstr='$bravecto_plus', GradientType=0);
    }
    .btn.cancel{
      background-color: $bravecto;
      border-bottom: 2px solid $bravecto;
    }
    .btn.btn-primary{
      background-color: $bravecto_plus;
      border-bottom: 2px solid #bb0009;
    }
    .multi-reminder{
      background-color: $bravecto_plus;
      margin: 0 -16px !important;
      padding: 5px 16px;

      .reminder_label{
        color: #ffffff;
        padding: 0 0 0 24px;
      }
    }
    //.row.col4{
    //  background-color: $bravecto;
    //  margin: inherit -16px;
    //}

  }

  .bravecto_enesimo{
    .intro-bravecto_enesimo{
      margin-bottom: 10px;

      .radio-inline{
        padding-top: 0;
        label{
          font-weight: 300;
        }
      }
    }
    .btn_dog.active{
      background-color: $bravecto_enesimo_start;
      background: $dog_active;
      background: $dog_active,-moz-linear-gradient(top, $bravecto_enesimo_start 0%, $bravecto_enesimo 80%, $bravecto_enesimo 100%);
      background: $dog_active,-webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_enesimo_start), color-stop(80%, $bravecto_enesimo), color-stop(100%, $bravecto_enesimo));
      background: $dog_active,-webkit-linear-gradient(top, $bravecto_enesimo_start 0%, $bravecto_enesimo 80%, $bravecto_enesimo 100%);
      background: $dog_active,-o-linear-gradient(top, $bravecto_enesimo_start 0%, $bravecto_enesimo 80%, $bravecto_enesimo 100%);
      background: $dog_active,-ms-linear-gradient(top, $bravecto_enesimo_start 0%, $bravecto_enesimo 80%, $bravecto_enesimo 100%);
      background: $dog_active,linear-gradient(to bottom, $bravecto_enesimo_start 0%, $bravecto_enesimo 80%, $bravecto_enesimo 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_enesimo_start', endColorstr='$bravecto_enesimo', GradientType=0);
    }
    .btn.cancel{
      background-color: $bravecto;
      border-bottom: 2px solid $bravecto;
    }
    .btn.btn-primary{
      background-color: $bravecto_enesimo;
      border-bottom: 2px solid $bravecto_enesimo;
    }
    .multi-reminder{
      background-color: $bravecto_enesimo;
      margin: 0 -16px !important;
      padding: 5px 16px;

      .reminder_label{
        color: #ffffff;
        padding: 0 0 0 24px;
      }

      .datapicker input[type=text].form-control {
        width: auto;
        display: inline;
      }
    }
    //.row.col4{
    //  background-color: $bravecto;
    //  margin: inherit -16px;
    //}

  }
    .scalibor{
    .btn_dog.active{
      background-color: $scalibor_light;
      background: $dog_active;
      background: $dog_active,-moz-linear-gradient(top, $scalibor_light 0%, $scalibor 80%, $scalibor 100%);
      background: $dog_active,-webkit-gradient(left top, left bottom, color-stop(0%, $scalibor_light), color-stop(80%, $scalibor), color-stop(100%, $scalibor));
      background: $dog_active,-webkit-linear-gradient(top, $scalibor_light 0%, $scalibor 80%, $scalibor 100%);
      background: $dog_active,-o-linear-gradient(top, $scalibor_light 0%, $scalibor 80%, $scalibor 100%);
      background: $dog_active,-ms-linear-gradient(top, $scalibor_light 0%, $scalibor 80%, $scalibor 100%);
      background: $dog_active,linear-gradient(to bottom, $scalibor_light 0%, $scalibor 80%, $scalibor 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
    }
    .btn.cancel{
      background-color: #243444;
      border-bottom: 2px solid #000;
    }
    .btn.btn-primary{
      background-color: $scalibor;
      border-bottom: 2px solid #c97a00;
    }
    .multi-reminder{
      background-color: $scalibor;
      margin: 0 -16px !important;
      padding: 5px 16px;

      .reminder_label{
        color: #ffffff;
        padding: 0 0 0 24px;

      }

    }
  }

  .nobivac{
    .multi-date{
      position:relative;
      margin-bottom: 20px;
    }

    .info_text{
      font-size: 13px;
    }

    .btn_dog.active{
      background: $dog_active $nobivac_animal;
    }
    .btn_cat.active{
      background: $cat_active $nobivac_animal;
    }
    .btn_rabbit.active{
      background: $rabbit_active $nobivac_animal;
    }

    .btn.cancel{
      background-color: $nobivac_animal;
      border-bottom: 2px solid #000;
    }
    .btn.btn-primary{
      background-color: #0069b1;
      border-bottom: 2px solid #003477;
    }
  }
  .letifend{
    .multi-date{
      position:relative;
      margin-bottom: 20px;
    }

    .info_text{
      font-size: 13px;
    }

    .btn_dog.active{
      background: $dog_active $letifend_animal;
    }
    .btn_cat.active{
      background: $cat_active $letifend_animal;
    }
    .btn_rabbit.active{
      background: $rabbit_active $letifend_animal;
    }

    .btn.cancel{
      background-color: $letifend_animal;
      border-bottom: 2px solid #000;
    }
    .btn.btn-primary{
      background-color: #0069b1;
      border-bottom: 2px solid #003477;
    }
  }
  .epomeni_episkepsi{
    .multi-date{
      position:relative;
      margin-bottom: 20px;
    }
    .btn_dog.active{
      background: $dog_active $verde;
    }
    .btn_cat.active{
      background: $cat_active $verde;
    }
    .btn_rabbit.active{
      background: $rabbit_active $verde;
    }
    .btn.cancel{
      background-color: $verde_scuro;
      border-bottom: 2px solid #243444;
    }
    .btn.btn-primary{
      background-color: $verde;
      border-bottom: 2px solid $verde_scuro;
    }
  }
  .geniki_therapeia{
    .btn_dog.active{
      background: $dog_active $nobivac;
    }
    .btn_cat.active{
      background: $cat_active $nobivac;
    }
    .btn_rabbit.active{
      background: $rabbit_active $nobivac;
    }
    .btn.cancel{
      background-color: $verde_scuro;
      border-bottom: 2px solid #243444;
    }
    .btn.btn-primary{
      background-color: $verde;
      border-bottom: 2px solid $verde_scuro;
    }
  }

  .row.col4,.row.col3{
    margin-left: -7px;
    margin-right: -7px;

    .col-sm-3.datapicker,.col-sm-4.datapicker{
      padding: 5px;

      .form-control{
        width: 90px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
      }
      .chk_reminder_multi{
        display: inline-block;
      }
    }
  }
  .reminder_multi:disabled{
    background-color: #cccccc;
  }

  .modal-header{
    padding-bottom: 0;
    border-bottom: none;
  }
  .nav-link{
    color: $msd_blue_color;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: $verde;
  }
  #open-preview{
    background: url("../images/reminder/bt_anteprima.png") no-repeat top center;
    width: 100px;
    height: 72px;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    div{
      position: absolute;
      bottom: 0;
      text-transform: uppercase;
      font-size: 0.7em;
    }
  }
  #open-preview:hover{
    background: url("../images/reminder/bt_anteprima_over.png") no-repeat top center;
  }
  .sms_bkg{
    background: url("../images/reminder/phone.png") no-repeat center;
    height: 465px;

    #sms_text{
      padding: 0;
      position: absolute;
      top: 355px;
      left: 275px;
      width: 300px;
      text-align: left;
      font-size: 17px;
    }
  }

  //#modalRequired{
  //  .modal-dialog
  //  {
  //    -webkit-transform: translate(0, calc(50vh - 50%));
  //    -ms-transform: translate(0, 50vh) translate(0, -50%);
  //    -o-transform: translate(0, calc(50vh - 50%));
  //    transform: translate(0, 50vh) translate(0, -50%);
  //  }
  //}
}

.special_messages{

  h2{
    text-align: center;
    text-transform: uppercase;
    color: $verde;

  }
  label{
    font-weight: bold;
  }
  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
    background-color: #eeeeee;
    opacity: 1;
  }
  .covid_message_counter{
    padding-bottom: 10px;
  }

  .legenda{
    color: #a5a6a8;
  }

  .btn.cancel{
    background-color: $verde_scuro;
    border-bottom: 2px solid #243444;
  }
}

@media screen and (max-width: 576px) {
  .reminder_page{
    // position: unset;
    left: auto;
    width: auto;
    margin: auto;
    .btn_animals{
      width: 50px;
      height: 50px;
      background-size: initial;
    }
  }
  .reminder_page .multi-reminder{
    font-size: 0.8rem;
    .col-3.datapicker,.col-4.datapicker{
      text-align: center;
      margin-top: 0.5rem;
      input{
        font-size: 0.8rem;
        padding: 0.35rem;
      }
    }
    
  }
  #open-preview{
    display: none;
  }
}