@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.eot');
    src: url('../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.woff2') format('woff2'),
        url('../fonts/invention/037cdf0dfafbc560b44f50a3142e7670.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/b6aeae6e9751258d6009b654c592c241.eot');
    src: url('../fonts/invention/b6aeae6e9751258d6009b654c592c241.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/b6aeae6e9751258d6009b654c592c241.woff2') format('woff2'),
        url('../fonts/invention/b6aeae6e9751258d6009b654c592c241.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/d14d33ff26cf1261579fbf28545982c9.eot');
    src: url('../fonts/invention/d14d33ff26cf1261579fbf28545982c9.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/d14d33ff26cf1261579fbf28545982c9.woff2') format('woff2'),
        url('../fonts/invention/d14d33ff26cf1261579fbf28545982c9.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.eot');
    src: url('../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.woff2') format('woff2'),
        url('../fonts/invention/c9b2834f15e7458aa6c7dd0aadd42a26.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.eot');
    src: url('../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.woff2') format('woff2'),
        url('../fonts/invention/79c664de53d42d7aa7b9fb0c44c57148.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/d6ba5077c0999241f6520681a41eac25.eot');
    src: url('../fonts/invention/d6ba5077c0999241f6520681a41eac25.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/d6ba5077c0999241f6520681a41eac25.woff2') format('woff2'),
        url('../fonts/invention/d6ba5077c0999241f6520681a41eac25.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.eot');
    src: url('../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.woff2') format('woff2'),
        url('../fonts/invention/ad93a8285723f5f290f7250c6dcf8f46.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/ba6ade37a504590140f80856e246cf2f.eot');
    src: url('../fonts/invention/ba6ade37a504590140f80856e246cf2f.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/ba6ade37a504590140f80856e246cf2f.woff2') format('woff2'),
        url('../fonts/invention/ba6ade37a504590140f80856e246cf2f.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.eot');
    src: url('../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.woff2') format('woff2'),
        url('../fonts/invention/cebc7390ac1fec7efa04f229fbfa5bd8.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.eot');
    src: url('../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.woff2') format('woff2'),
        url('../fonts/invention/1074f64026d0ba34a97b375d6c9c7454.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.eot');
    src: url('../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.woff2') format('woff2'),
        url('../fonts/invention/e0c9834d1352db7d9ddbc5d84c21544c.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Invention';
    src: url('../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.eot');
    src: url('../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.eot?#iefix') format('embedded-opentype'),
        url('../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.woff2') format('woff2'),
        url('../fonts/invention/63a86c9f34219ec24b8afcd64ffec4e6.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

