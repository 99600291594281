/**********************************************************************************************
                                       Pagina di Registrazione
**********************************************************************************************/

.register_page{
  position: relative;
  width: 500px;
  background: $body-bg;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  top: 40px;
  left: 50%;
  margin-left: -250px;
  margin-bottom: 150px;
  margin-top: 50px;

  button.btn{
    border-bottom: none;
  }
  .header_box, .footer_box{
    padding-top: 30px;
  }
  h3{
    color: $verde;
    font-size: 18px;
    font-weight: 600;
  }
  label{
    font-weight: 300;
  }
  .checkbox label{
    color: $msd_blue_color;

    a{
      color: $msd_blue_color;
      text-decoration: underline;
      strong{
        font-weight: bold;
      }
    }
  }
  .modal{
    z-index: 1070;
  }
  #ModalTermini{
    .signature-container{
      padding: 6px 0;
      color: $bravecto_start;
      font-weight: 600;
    }
    .form-control-feedback{
        right: 15px;
    }
  }
}
@media screen and (max-width: 576px) {
  .register_page{
    position: unset;
    width: auto;
    margin: auto;
  }
}