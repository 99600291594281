/**********************************************************************************************
                                       Home - Dashboard
**********************************************************************************************/
.fluid-reminder {
  position: relative;
  z-index: 9999999;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(23%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.container-reminder {
  padding-top: 20px;

  .content-left {
    color: $msd_blue_color;
    font-size: 14px;

    .intro {
      text-align: center;
      margin-bottom: 10px;
    }

    .box-activity {
      background: $body-bg;
      margin-bottom: 5px;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 115px;

      .number-activity {
        color: $nobivac;
        font-size: 36px;
        font-weight: 700;
      }
    }
  }

  .title-reminder {
    display: block;
    color: $msd_blue_color;
    text-align: center;
    text-transform: none;
    position: relative;
    margin-top: 20px;

    span {
      background: #fff;
      font-size: 18px;
      padding: 0 20px;
      position: relative;
      z-index: 5;
      font-family: 'Invention';
      font-weight: 700;
    }
  }

  .title-reminder::before {
    content: "";
    display: block;
    border-top: solid 2px $box_generico_light;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1;
  }


  .content-reminder {
    background: $body-bg;
    box-shadow: 0px 0px 5px #000;
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
    .row{
      margin-left: auto;
      margin-right: auto;
    }
  }


  .privacy_box {
    text-align: center;
    color: $msd_blue_color;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;

    a{
      color: $verde;
      font-weight: bold;
    }
  }

}

.box-reminder {
  height: 96px;

  .logo {
    //height: 60px;
    // padding: 15px;
    text-align: center;
  }

  .testo {
    height: 100%;
    color: #ffffff;
    vertical-align: middle;
    // padding: 15px;
    // line-height: 60px;
    font-size: 20px;
    // line-height: normal;
        display: flex;
  }
}

.box-reminder.box-bravecto {
  border: 2px solid $bravecto;

  .testo {
    background-color: $bravecto;
  }

  .testo {
    background: $bravecto;
    background: -moz-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_light), color-stop(100%, $bravecto), color-stop(100%, $bravecto));
    background: -webkit-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -o-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: -ms-linear-gradient(top, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    background: linear-gradient(to bottom, $bravecto_light 0%, $bravecto 100%, $bravecto 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_light', endColorstr='$bravecto', GradientType=0);
  }
}

.box-reminder.box-bravecto_enesimo {
  border: 2px solid $bravecto_enesimo;

  .testo {
    background-color: $bravecto_enesimo;
  }

  .testo {
    background: $bravecto_enesimo;
    background: -moz-linear-gradient(top, $bravecto_enesimo_light 0%, $bravecto_enesimo 100%, $bravecto_enesimo 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_enesimo_light), color-stop(100%, $bravecto_enesimo), color-stop(100%, $bravecto_enesimo));
    background: -webkit-linear-gradient(top, $bravecto_enesimo_light 0%, $bravecto_enesimo 100%, $bravecto_enesimo 100%);
    background: -o-linear-gradient(top, $bravecto_enesimo_light 0%, $bravecto_enesimo 100%, $bravecto_enesimo 100%);
    background: -ms-linear-gradient(top, $bravecto_enesimo_light 0%, $bravecto_enesimo 100%, $bravecto_enesimo 100%);
    background: linear-gradient(to bottom, $bravecto_enesimo_light 0%, $bravecto_enesimo 100%, $bravecto_enesimo 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_enesimo_light', endColorstr='$bravecto_enesimo', GradientType=0);
  }
}
.box-reminder.box-bravecto_plus {
  border: 2px solid $bravecto_plus;

  .testo {
    background-color: $bravecto_plus;
  }

  .testo {
    background: $bravecto_plus;
    background: -moz-linear-gradient(top, $bravecto_plus_light 0%, $bravecto_plus 100%, $bravecto_plus 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $bravecto_plus_light), color-stop(100%, $bravecto_plus), color-stop(100%, $bravecto_plus));
    background: -webkit-linear-gradient(top, $bravecto_plus_light 0%, $bravecto_plus 100%, $bravecto_plus 100%);
    background: -o-linear-gradient(top, $bravecto_plus_light 0%, $bravecto_plus 100%, $bravecto_plus 100%);
    background: -ms-linear-gradient(top, $bravecto_plus_light 0%, $bravecto_plus 100%, $bravecto_plus 100%);
    background: linear-gradient(to bottom, $bravecto_plus_light 0%, $bravecto_plus 100%, $bravecto_plus 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bravecto_plus_light', endColorstr='$bravecto_plus', GradientType=0);
  }
}

.box-reminder.box-scalibor {
  border: 2px solid $scalibor;

  .testo {
    background: $scalibor;
  }

  .testo {
    background: $scalibor;
    background: -moz-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $scalibor_light), color-stop(100%, $scalibor), color-stop(100%, $scalibor));
    background: -webkit-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -o-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: -ms-linear-gradient(top, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    background: linear-gradient(to bottom, $scalibor_light 0%, $scalibor 100%, $scalibor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$scalibor_light', endColorstr='$scalibor', GradientType=0);
  }

}

.box-reminder.box-nobivac {
  border: 2px solid $nobivac;

  .logo {
    // padding-top: 30px;
  }

  .testo {
    background: $nobivac;
    background: -moz-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $nobivac_light), color-stop(100%, $nobivac), color-stop(100%, $nobivac));
    background: -webkit-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -o-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: -ms-linear-gradient(top, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    background: linear-gradient(to bottom, $nobivac_light 0%, $nobivac 100%, $nobivac 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$nobivac', GradientType=0);
  }
}
.box-reminder.box-letifend {
  border: 2px solid $letifend;

  .logo {
    // padding-top: 30px;
  }

  .testo {
    background: $letifend;
    background: -moz-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $letifend_light), color-stop(100%, $letifend), color-stop(100%, $letifend));
    background: -webkit-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -o-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: -ms-linear-gradient(top, $letifend_light 0%, $letifend 100%, $letifend 100%);
    background: linear-gradient(to bottom, $letifend_light 0%, $letifend 100%, $letifend 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$letifend_light', endColorstr='$letifend', GradientType=0);
  }
}

.box-reminder.box-visita {
  border: 2px solid $box_visita;

  .logo {
    padding: 0px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .testo {
    background: $box_visita;
    background: -moz-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $box_visita_light), color-stop(100%, $box_visita), color-stop(100%, $box_visita));
    background: -webkit-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -o-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: -ms-linear-gradient(top, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    background: linear-gradient(to bottom, $box_visita_light 0%, $box_visita 100%, $box_visita 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
  }
}

.box-reminder.box-generico {
  border: 2px solid $box_generico;

  .logo {
    padding: 0px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .testo {
    background: $box_visita;
    background: -moz-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $box_generico_light), color-stop(100%, $box_generico), color-stop(100%, $box_generico));
    background: -webkit-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -o-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: -ms-linear-gradient(top, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    background: linear-gradient(to bottom, $box_generico_light 0%, $box_generico 100%, $box_generico 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
  }
}


.box-special-message {
  border: 2px solid $box_special_message;

  .logo {
    padding: 0px !important;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .testo {
    color: white !important;

    background: $box_visita;
    background: -moz-linear-gradient(top, $box_special_message_light 0%, $box_special_message 100%, $box_special_message 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $box_special_message_light), color-stop(100%, $box_special_message), color-stop(100%, $box_special_message));
    background: -webkit-linear-gradient(top, $box_special_message_light 0%, $box_special_message 100%, $box_special_message 100%);
    background: -o-linear-gradient(top, $box_special_message_light 0%, $box_special_message 100%, $box_special_message 100%);
    background: -ms-linear-gradient(top, $box_special_message_light 0%, $box_special_message 100%, $box_special_message 100%);
    background: linear-gradient(to bottom, $box_special_message_light 0%, $box_special_message 100%, $box_special_message 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$nobivac_light', endColorstr='$box_visita', GradientType=0);
  }
}



@media screen and (max-width: 576px) {
  .container-reminder 
  {
    .privacy_box{
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .content-left .box-activity .number-activity{
      font-size: 2rem;
    }
    .box-reminder 
    {
      .testo{
        font-size: 1.25rem;
        line-height: normal;
        display: flex;
      }
      .logo
      {
        display: flex;
        img{
          height: auto !important;
        }
      }
      
    }
  }
}