/**********************************************************************************************
                                       Pagina di Login
**********************************************************************************************/

.login_page{
  position: relative;
  padding: 30px 0 0 0;
  width: 500px;
  background: $body-bg;
  box-shadow: 0px 0px 5px #000;
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  top: 40px;
  left: 50%;
  margin-left: -250px;
  margin-bottom: 150px;
  margin-top: 50px;

  button.btn{
    border-bottom: none;
  }

  .login_box{
    padding: 0 30px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    h3{
      color: $verde;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
    }
    .intro{
      margin-bottom: 20px;
      font-weight: 600;
    }
    label{
      font-weight: 300;
    }
    .password-lost{
      text-align: right;
      color: $msd_blue_color;
      text-decoration: underline;
    }
    .code-email{
      margin-bottom: 15px;
    }
  }
  .login_registration_box{
    padding: 15px 30px;
    border-top: 1px solid $grigio;
    border-bottom: 1px solid $grigio;

    .form-group{
      margin-bottom: 10px;
    }
    .text-center{
      font-weight: 600;
    }
  }
  .header_box, .footer_box{
    padding: 30px 0;
  }
}

@media screen and (max-width: 576px) {
  .login_page{
    position: unset;
    width: auto;
    margin: auto;
  }
}